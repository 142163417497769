import ProjectCard from "../../../../src/components/ProjectCard";
import * as aoe2techtree from "../../../../content/projects/aoe2techtree.json";
import * as aoe2cm from "../../../../content/projects/aoe2cm.json";
import * as aoe2map from "../../../../content/projects/aoe2map.json";
import * as website from "../../../../content/projects/website.json";
import * as React from 'react';
export default {
  ProjectCard,
  aoe2techtree,
  aoe2cm,
  aoe2map,
  website,
  React
};